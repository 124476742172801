document.addEventListener("DOMContentLoaded", () => {
	mappingFunc();
	hideElement();
	swiperInit();
	setBackground();
	counterAnimate();
	generalFnc();
	filterItem();
	backToTop();
	stickyNav();
	scrollReveal();
});

window.addEventListener("resize", function () {
	let width = this.window.innerWidth;
	if (width <= 1024.1) {
		mappingFunc();
		hideElement();
	}
});
window.addEventListener("scroll", function () {
	const nav = document.querySelector(".side-nav-tool");
	if (
		document.body.scrollTop > $(window).height() ||
		document.documentElement.scrollTop > $(window).height()
	) {
		nav.classList.add("show");
	} else {
		nav.classList.remove("show");
	}
	if ($(window).width() > 1200.1) {
		if (
			document.body.scrollTop > 100 ||
			document.documentElement.scrollTop > 100
		) {
			$("header").addClass("minimize");
		} else {
			$("header").removeClass("minimize");
		}
	}
	if (
		document.body.scrollTop > $(window).height() ||
		document.documentElement.scrollTop > $(window).height()
	) {
		$(".side-nav-tool").addClass("show");
	} else {
		$(".side-nav-tool").removeClass("show");
	}
});

function scrollReveal() {
	let fadeDown = {
		origin: "top",
		duration: 950,
		delay: 200,
		interval: 150,
		reset: false,
		distance: "50%",
		mobile: false
	};
	let fadeUp = {
		origin: "bottom",
		duration: 950,
		delay: 150,
		interval: 50,
		reset: false,
		distance: "50%",
		mobile: false
	};
	let fadeUp2 = {
		origin: "bottom",
		duration: 950,
		delay: 300,
		interval: 50,
		reset: false,
		distance: "50%",
		mobile: false
	};
	let fadeUp3 = {
		origin: "bottom",
		duration: 950,
		delay: 450,
		interval: 50,
		reset: false,
		distance: "50%",
		mobile: false
	};
	let fadeUp4 = {
		origin: "bottom",
		duration: 950,
		delay: 600,
		interval: 50,
		reset: false,
		distance: "50%",
		mobile: false
	};
	let fadeLeft = {
		origin: "left",
		duration: 950,
		delay: 200,
		interval: 50,
		reset: false,
		distance: "50%",
		mobile: false
	};
	let fadeRight = {
		origin: "right",
		duration: 950,
		delay: 200,
		interval: 50,
		reset: false,
		distance: "50%",
		mobile: false
	};
	let fadeIn = {
		origin: "top",
		duration: 1100,
		delay: 320,
		interval: 150,
		reset: false,
		distance: 0,
		mobile: false
	};
	ScrollReveal().reveal(".fd-down", fadeDown, {
		easing: "cubic-bezier(.38,.37,.42,.42)",
	});
	ScrollReveal().reveal(".fd-up", fadeUp, {
		easing: "cubic-bezier(.38,.37,.42,.42)",
	});
	ScrollReveal().reveal(".fd-up-2", fadeUp2, {
		easing: "cubic-bezier(.38,.37,.42,.42)",
	});
	ScrollReveal().reveal(".fd-up-3", fadeUp3, {
		easing: "cubic-bezier(.38,.37,.42,.42)",
	});
	ScrollReveal().reveal(".fd-up-4", fadeUp4, {
		easing: "cubic-bezier(.38,.37,.42,.42)",
	});
	ScrollReveal().reveal(".fd-left", fadeLeft, {
		easing: "cubic-bezier(.38,.37,.42,.42)",
	});
	ScrollReveal().reveal(".fd-right", fadeRight, {
		easing: "cubic-bezier(.38,.37,.42,.42)",
	});
	ScrollReveal().reveal(".fd-in", fadeIn, {
		easing: "cubic-bezier(.38,.37,.42,.42)",
	});
}

function backToTop() {
	let btn = $(".back-to-top");
	btn.on("click", function (e) {
		e.preventDefault();
		$("html, body").animate(
			{
				scrollTop: 0,
			},
			"300"
		);
	});
}
function stickyNav() {
	if ($(".sticky-nav").length) {
		$(".sticky-nav").scrollToFixed({
			zIndex: 99,
			marginTop: $("header").outerHeight(),
		});
	}

	$(window)
		.scroll(function () {
			var scrollDistance = $(window).scrollTop();
			// Assign active class to nav links while scolling
			$(".about-section-wrapper .about-section-id").each(function (i) {
				if (
					$(this).position().top -
						$(".sticky-nav").outerHeight() -
						$("header").outerHeight() -
						1 <=
					scrollDistance
				) {
					$(".sticky-nav li.active").removeClass("active");
					$(".sticky-nav li").eq(i).addClass("active");
				}
			});
		})
		.scroll();
	$(".sticky-nav a").on("click", function (event) {
		if (this.hash !== "") {
			let offset =
				$("header").outerHeight() + $(".sticky-nav").outerHeight();
			var hash = this.hash;
			$("html, body").animate(
				{
					scrollTop: $(hash).offset().top - offset,
				},
				800,
				function () {
					// window.location.hash = hash;
				}
			);
		} // End if
	});
}

function filterItem() {
	$(".filter-display-wrapper").each(function () {
		let x = 8;
		$(this).find(".col-item").slice(0, 8).show();
		let size = $(this).find(".col-item").length;
		if (size < x || size == x) {
			$(this).find(".grid-center").addClass("disble-btn");
		}
		$(".filter-display-wrapper")
			.find(".btn-primary")
			.on("click", function (e) {
				e.preventDefault();
				x = x + 8;
				$(this)
					.parents(".filter-display-wrapper")
					.find(".col-item")
					.slice(0, x)
					.slideDown();
				if (x >= size || size < x) {
					$(this).slideUp();
				}
			});
	});
}
Fancybox.bind("[data-fancybox]", {
	parentEl: document.forms[0], // Element containing main structure
});

function generalFnc() {
	//Toggle Javascript code

	const bodyWrap = document.querySelector("body");
	const menuToggle = document.querySelector(".menu-toggle");

	const hamburger = document.querySelector(".hamburger");
	const menuMobile = document.querySelector(".mobile-nav-wrap");

	const searchToggle = document.querySelector(".search-toggle");
	const search = document.querySelector(".searchbox");
	const backTop = document.querySelector(".back-to-top");

	//const Cart
	const cart = document.querySelector(".shopping-cart-wrapper");

	// Menu function

	menuToggle.addEventListener("click", () => {
		menuMobile.classList.toggle("is-open");
		hamburger.classList.toggle("is-active");
		bodyWrap.classList.toggle("overlay-bg");
	});
	//Search Toggle

	searchToggle.addEventListener("click", function (event) {
		search.classList.toggle("open");
	});

	if ($(window).width() < 1200) {
		$(".drop-down .title em").on("click", function () {
			if ($(this).parents(".drop-down").hasClass("is-open")) {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("is-open");
			} else {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("is-open");
				$(this).parent().next().slideDown();
				$(this).parents(".drop-down").addClass("is-open");
			}
		});
		$(".drop-mega >.title em").on("click", function () {
			if ($(this).parents(".drop-mega").hasClass("open-mega")) {
				$(".drop-mega .mega-wrap").slideUp();
				$(".drop-mega").removeClass("open-mega");
			} else {
				$(".drop-mega .mega-wrap").slideUp();
				$(".drop-mega").removeClass("open-mega");
				$(this).parent().next().slideDown();
				$(this).parents(".drop-mega").addClass("open-mega");
			}
		});
		$(".dropdown .title em").on("click", function () {
			if ($(this).parents(".dropdown").hasClass("open-sub")) {
				$(".dropdown .menu-sub-2").slideUp();
				$(".dropdown").removeClass("open-sub");
			} else {
				$(".dropdown .menu-sub-2").slideUp();
				$(".dropdown").removeClass("open-sub");
				$(this).parent().next().slideDown();
				$(this).parents(".dropdown").addClass("open-sub");
			}
		});
		$(".tertiary-menu-toggle").on("click", function () {
			$(".nav-tertiary-menu").slideToggle();
		});
	}



	$(".read-more-wrap").each(function () {
		if ($(this).find(".desc").outerHeight() < 70) {
			$(this).find(".btn-view-more").addClass('disable-btn');
		} else {
			$(this).find(".desc").css({
				height: "70",
			});
		}
		let button = $(this).find(".btn-view-more");
		let content = $(this);
		button.on("click", function () {
			content.toggleClass("open-view");
		});
	});

	//Commnet append

	if ($("body").hasClass("product-detail-page")) {
		if ($(".commentpanel").length) {
			$(".commentpanel").appendTo(".product-rating-wrap");
		}
	}

	document.addEventListener("click", (event) => {
		if (!event.target.closest(".search-toggle, .searchbox")) {
			search.classList.remove("open");
		}
		if (!event.target.closest(".mobile-nav-wrap, .menu-toggle")) {
			menuMobile.classList.remove("is-open");
			bodyWrap.classList.remove("overlay-bg");
			hamburger.classList.remove("is-active");
		}
	});
	//Side Nav  and back to top
	backTop.addEventListener("click", (event) => {
		event.preventDefault();
		$("html, body").animate({ scrollTop: 0 }, "300");
	});
	//Tab function

	$(".tab-nav li a").on("click", function () {
		$(this).parents(".tab-nav").find("li").removeClass("active");
		$(this).parents("li").addClass("active");

		var display = $(this).attr("data-type");
		$(this).parents(".tab-panel").find(".tab-item").removeClass("active");
		$(this).parents(".tab-panel").find("#" + display).addClass("active");
	});
}

function swiperInit() {
	var galleryThumbArray = [];
	var galleryMainArray = [];

	let swiperThumbInstance = $(".gallery-thumb .swiper");
	for (let index = 0; index < swiperThumbInstance.length; index++) {
		let $this = $(swiperThumbInstance[index]);
		$this.addClass("thumb-instance-" + index);
		$this
			.parent()
			.find(".nav-prev")
			.addClass("thumb-prev-" + index);
		$this
			.parent()
			.find(".nav-next")
			.addClass("thumb-next-" + index);
		$this
			.parent()
			.find(".swiper-pagination")
			.addClass("pagination-thumb-" + index);
		var galleryThumb = new Swiper(".thumb-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			slidesPerView: 4,
			navigation: {
				nextEl: ".thumb-next-" + index,
				prevEl: ".thumb-prev-" + index,
			},
			pagination: {
				el: ".pagination-thumb-" + index,
				type: "bullets",
				clickable: true,
			},
		});
		galleryThumbArray.push(galleryThumb);
	}

	let swiperMainInstances = $(".gallery-main  .swiper");
	for (let index = 0; index < swiperMainInstances.length; index++) {
		let $this = $(swiperMainInstances[index]);
		$this.addClass("main-instance-" + index);
		$this
			.parent()
			.find(".nav-prev")
			.addClass("main-prev-" + index);
		$this
			.parent()
			.find(".nav-next")
			.addClass("main-next-" + index);
		$this
			.parent()
			.find(".swiper-pagination")
			.addClass("pagination-main-" + index);
		var galleryMain = new Swiper(".main-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			slidesPerView: 1,
			navigation: {
				nextEl: ".main-next-" + index,
				prevEl: ".main-prev-" + index,
			},
			pagination: {
				el: ".pagination-main-" + index,
				type: "bullets",
				clickable: true,
			},
		});
		galleryMainArray.push(galleryMain);
	}

	for (var index = 0; index < galleryThumbArray.length; index++) {
		var thumbSwiper = galleryThumbArray[index];
		var mainSwiper = galleryMainArray[index];

		thumbSwiper.controller.control = mainSwiper;
		mainSwiper.controller.control = thumbSwiper;
	}

	var swiperGrid = new Swiper(".grid-swiper .swiper", {
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		observer: true,
		spaceBetween: 30,
		observeParents: true,
		slidesPerView: 4,
		grid: {
			rows: 2,
		},
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
	});

	var primarySwiper = new Swiper(".primary-banner .swiper", {
		// Optional parameters
		slidesPerView: 1,
		speed: 1205,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		effect: "fade",
		fadeEffect: {
			crossFade: true,
		},

		pagination: {
			el: ".primary-banner  .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});
	$(".trinity-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("tri-instance-" + index);
		$this
			.parent()
			.find(".nav-prev")
			.addClass("btn-prev-tri-" + index);
		$this
			.parent()
			.find(".nav-next")
			.addClass("btn-next-tri-" + index);

		var swiper = new Swiper(".tri-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			loop: false,
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: ".btn-next-tri-" + index,
				prevEl: ".btn-prev-tri-" + index,
			},
		});
	});

	$(".four-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("instance-four" + index);
		$this
			.parent()
			.find(".nav-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-next")
			.addClass("btn-next-" + index);

		var swiper = new Swiper(".instance-four" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			loop: false,
			speed: 750,
			observer: true,
			spaceBetween: 20,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 2,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1280: {
					slidesPerView: 4,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
		});
	});

	$(".single-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("single-instance-" + index);
		$this
			.parent()
			.find(".nav-prev")
			.addClass("btn-prev-single-" + index);
		$this
			.parent()
			.find(".nav-next")
			.addClass("btn-next-single-" + index);
		$this
			.parent()
			.find(".swiper-pagination")
			.addClass("pagination-instance-" + index);

		var swiper = new Swiper(".single-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			loop: false,
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			slidesPerView: 1,
			navigation: {
				nextEl: ".btn-next-single-" + index,
				prevEl: ".btn-prev-single-" + index,
			},
			pagination: {
				el: ".pagination-instance-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	});

	var partnerSwiper = new Swiper(".partner-swiper .swiper", {
		// Optional parameters
		speed: 1205,
		spaceBetween: 30,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		breakpoints: {
			200: {
				slidesPerView: 2,
				spaceBetween: 10,
			},
			576: {
				slidesPerView: 3,
			},
			769: {
				slidesPerView: 4,
			},
			1024: {
				slidesPerView: 5,
			},
			1200: {
				slidesPerView: 6,
			},
		},
		lazy: {
			loadPrevNext: true,
		},
		navigation: {
			nextEl: ".partner-swiper  .nav-next",
			prevEl: ".partner-swiper  .nav-prev",
		},
		pagination: {
			el: ".partner-swiper .swiper-pagination",
			type: "bullets",
			dynamicBullets: true,
			clickable: true,
		},
	});


	var historyThumbs = new Swiper(".swiper-history-thumb  .swiper", {
		spaceBetween: 5,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
			1280: {
				slidesPerView: 6,
			},
			1440: {
				slidesPerView: 8,
			},
		},

		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});
	var historyMain = new Swiper(".swiper-history-main .swiper", {
		slidesPerView: 1,
		centeredSlides: true,
		lazy: {
			loadPrevNext: true,
		},
		autoplay: {
			delay: 3000,
		},
		speed: 750,
		loop: false,
		thumbs: {
			swiper: historyThumbs,
		},
		navigation: {
			nextEl: ".swiper-history-main .nav-next",
			prevEl: ".swiper-history-main .nav-prev",
		},
	});



}

// Side
function setBackground() {
	const elements = document.querySelectorAll("[setBackground]");
	elements.forEach((element) => {
		element.style.cssText = `
		background-image: url(${element.getAttribute("setBackground")});
		background-size: cover;
		background-position: center;
	  `;
	});
}
function counterAnimate() {
	const counterItem = document.querySelectorAll(".counter");
	if (counterItem.length) {
		const counterUp = window.counterUp.default;
		const callback = (entries) => {
			entries.forEach((entry) => {
				const el = entry.target;
				if (
					entry.isIntersecting &&
					!el.classList.contains("is-visible")
				) {
					counterUp(el, {
						duration: 2000,
						delay: 200,
					});
					el.classList.add("is-visible");
				}
			});
		};
		const IO = new IntersectionObserver(callback, { threshold: 1 });

		counterItem.forEach((counter) => {
			IO.observe(counter);
		});
	}
}

function mappingFunc() {
	new MappingListener({
		selector: ".searchbox",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".search-wrapper",
		desktopMethod: "appendTo",
		breakpoint: 1200.1,
	}).watch();
	new MappingListener({
		selector: ".nav-primary-menu",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".menu-toggle",
		desktopMethod: "insertAfter",
		breakpoint: 1200.1,
	}).watch();
	new MappingListener({
		selector: ".language-wrapper",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".header-redirect",
		desktopMethod: "insertBefore",
		breakpoint: 1200.1,
	}).watch();
}
function hideElement() {
	if ($(window).width() < 1200.1) {
		const [search, menu, language] = [
			".searchbox",
			".nav-primary-menu",
			".language-wrapper",
		].map((selector) => document.querySelector(selector));

		[search, menu, language].forEach((el) => (el.style.display = "flex"));
	}
}
